import { Observable } from 'rxjs';
import { Categoria } from "./categoria";
import { Pais } from './pais';
import { BusinessUnit } from '../../dashboard/models/business-unit';

export class Distributor {
  id: number;
  nombre: string;
  codigo: string;
  razonSocial: string;
  cuit: string;
  categoria: Categoria;
  paisNodumId: number;
  paisOrigenId: number;
  pais: Pais;
  unidadesNegocio: BusinessUnit[];
  logo: string;
}

export abstract class DistributorData {
  abstract getList(): Observable<Distributor[]>;
  abstract getDistribuidoresByGerente(gerenteId: number): Observable<Distributor[]>;
}
