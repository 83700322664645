import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlService } from '../../../@core/services/urlService/url.service';
import { Articulo } from '../../dashboard/models/articulo';
import { CachingService } from '../../../@core/services/cachingService/caching.service';
import { CategoriaArticulo } from '../models/categoria-articulo';

@Injectable()
export class ArticuloService {
  private apiRoute: string;

  constructor(private urlService: UrlService, private cachingService: CachingService, private http: HttpClient) {
    this.apiRoute = `${this.urlService.getBaseUrl()}Articulo/`;
  }

  getArticulos(): Observable<Articulo[]> {
    const key = `Articulo::GetLista`;
    return this.cachingService.get(
      key,
      this.http.get<Articulo[]>(`${this.apiRoute}GetLista`),
    );
  }

  getArticulosByPais(pais: string): Observable<Articulo[]> {
    const key = `Articulo::GetListaByPais`;
    return this.http.get<Articulo[]>(`${this.apiRoute}GetListaByPais/${pais}`);
  }

  update(formData: FormData, id: number): Observable<any> {
    return this.http.post<any>(`${this.apiRoute}EditarArticulo/${id}`, formData);
  }

  cargaArticulosExcel(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiRoute}CargaArticulosExcel/`, formData);
  }
}
