import { Component, OnInit } from '@angular/core';
import { Config } from '../../@core/config/config';

@Component({
  selector: 'ngx-activacion-usuario',
  templateUrl: './activacion-usuario.component.html',
  styleUrls: ['./activacion-usuario.component.scss']
})
export class ActivacionUsuarioComponent implements OnInit {
  redirect: string = '';

  constructor() { }

  ngOnInit(): void {
    this.redirect = sessionStorage.getItem(Config.LocalStorage.redirect) != 'null' && sessionStorage.getItem(Config.LocalStorage.redirect) ? sessionStorage.getItem(Config.LocalStorage.redirect) : '';
  }
}
