import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Target, TargetStatus, QHeader, QHeaderMonths, TargetData } from '../models/target';
import { UrlService } from '../../../@core/services/urlService/url.service';
import * as moment from 'moment';

@Injectable()
export class TargetService extends TargetData {
  private apiRoute: string;
  constructor(private urlService: UrlService, private http: HttpClient) {
      super();
      this.apiRoute = `${this.urlService.getBaseUrl()}Objetivo/`;
  }
  getStatus(distributorId: number): Observable<TargetStatus> {
    return this.http.get<TargetStatus>(`${this.apiRoute}GetEstadoObjetivosByDistribuidor/` + distributorId);
  }
  aproveTarget(distributorId: number): Observable<any>  {
    return this.http.get<any>(`${this.apiRoute}AprobarObjetivosByDistribuidor/` + distributorId);
  }
  rejectTarget(distributorId: number): Observable<any>  {
    return this.http.get<any>(`${this.apiRoute}RechazarObjetivosByDistribuidor/` + distributorId);
  }
  getList(distributorId: number): Observable<Target[]>  {
    return this.http.get<Target[]>(`${this.apiRoute}GetObjetivosByDistribuidor/` + distributorId);
  }
  saveList(targets: Target[]): Observable<any>  {
    return this.http.post<any>(`${this.apiRoute}ActualizarObjetivosByDistribuidor/`, targets);
  }
  getHeaderMonths(lang: string): QHeader {
    const result = {Q1Header: {monthA: '', monthB: '', monthC: ''} as QHeaderMonths,
                    Q2Header: {monthA: '', monthB: '', monthC: ''} as QHeaderMonths,
                    Q3Header: {monthA: '', monthB: '', monthC: ''} as QHeaderMonths,
                    Q4Header: {monthA: '', monthB: '', monthC: ''} as QHeaderMonths,
                    } as QHeader;
    const headers = this.getQHeaders(lang);
    headers.forEach( (month, index) => {
      switch (index) {
        case 0: {
          result.Q1Header.monthA = month;
          break;
        }
        case 1: {
          result.Q1Header.monthB = month;
          break;
        }
        case 2: {
          result.Q1Header.monthC = month;
          break;
        }
        case 3: {
          result.Q2Header.monthA = month;
          break;
        }
        case 4: {
          result.Q2Header.monthB = month;
          break;
        }
        case 5: {
          result.Q2Header.monthC = month;
          break;
        }
        case 6: {
          result.Q3Header.monthA = month;
          break;
        }
        case 7: {
          result.Q3Header.monthB = month;
          break;
        }
        case 8: {
          result.Q3Header.monthC = month;
          break;
        }
        case 9: {
          result.Q4Header.monthA = month;
          break;
        }
        case 10: {
          result.Q4Header.monthB = month;
          break;
        }
        case 11: {
          result.Q4Header.monthC = month;
          break;
        }
        default: {
          break;
        }
      }
    });

    return result;
  }
  getQHeaders(lang: string): string[] {
    const startMonth = 9; // 9: october
    const dateStart = moment().month(startMonth).locale(lang);
    const now = moment();

    if (now.get('month') < startMonth) {
      dateStart.set(now.month(startMonth).year(now.get('year') - 1).toObject());
    }

    const labels_default = () => {
      const months = [];

      for (let i = 0; i < 12; i++) {
        const labelItem = dateStart.format('MMMM YYYY');
        months.push(labelItem.toUpperCase());
        dateStart.add(1, 'month');
      }

      return months;
    };
    return labels_default();
  }

  getEstadoObjetivosByPendienteAprobacion(): Observable<TargetStatus[]> {
    return this.http.get<TargetStatus[]>(`${this.apiRoute}GetEstadoObjetivosByPendienteAprobacion/`);
  }

  getCantidadObjetivosPendientesDeAprobacion(): Observable<number> {
    return this.http.get<number>(`${this.apiRoute}getObjetivosPendientesDeAprobacion/`);
  }

  getObjetivosMasivosTemplate(): Observable<any> {
    return this.http.get<any>(`${this.apiRoute}getObjetivosMasivosTemplate`);
  }

  cargaObjetivosMasivos(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiRoute}cargaObjetivosMasivos`, formData);
  }
}
