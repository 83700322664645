import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { CacheService } from './cache.service'
import { Observable, of } from 'rxjs'
import { switchMap } from 'rxjs/operators'

export enum Verbs {
    GET = 'GET',
}

@Injectable()
export class HttpCacheableService {

    constructor(
        private http: HttpClient,
        private _cacheService: CacheService
    ) { }

    get<T>(options: HttpOptions): Observable<T> {
        return this.httpCall(Verbs.GET, options)
    }

    private httpCall<T>(verb: Verbs, options: HttpOptions): Observable<T> {
        options.body = options.body ?? null
        options.cacheSecs = options.cacheSecs ?? 0

        if (options.cacheSecs > 0) {
            const data = this._cacheService.load(options.url)
            if (data !== null) {
                return of<T>(data)
            }
        }

        return this.http.request<T>(verb, options.url, {
            body: options.body
        }).pipe( 
            switchMap(response => {        
                if (options.cacheSecs > 0) {
                    this._cacheService.save({
                        key: options.url,
                        data: response,
                        expiraEnSegundos: options.cacheSecs
                    })
                }
                return of<T>(response)
            })
        )
    }
}

export class HttpOptions {
    url: string
    body?: any
    cacheSecs?: number
}