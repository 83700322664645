import { Observable } from "rxjs";

export class Modulo {
    id: number;
    nombre: string;
    nombreNormalizado: string;
    porcentajeCumplimiento: number;
    porcentajeDescuento: number;
}
export abstract class ModuloData {
    abstract getList(): Modulo[];
}