import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco'

@Injectable({
    providedIn: 'root'
  })
export class LangInterceptor implements HttpInterceptor{
    constructor(private i18nService: TranslocoService){}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let jsonReq: HttpRequest<any> = req.clone({
            setHeaders: {'x-idioma': this.i18nService.getActiveLang() }
        });        
        return next.handle(jsonReq);
    }
}