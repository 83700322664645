import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NbRoleProvider } from '@nebular/security';
import { UrlService } from '../urlService/url.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalstorageService } from '../localStorageService/local-storage-service.service';
import { Config } from '../../config/config';
import { Usuario } from '../../../pages/admin/models/usuario';
import { CachingService } from '../cachingService/caching.service';
import { PaisService } from '../pais.service';
import { Pais } from '../../../pages/admin/models/pais';
import { environment } from '../../../../environments/environment';
import { of } from 'rxjs';
import { Rol } from '../../../pages/admin/models/rol';
import { Grupo } from '../../../pages/admin/models/grupo';
import { Modulo } from '../../../pages/admin/models/modulo';
import { ModuloService } from '../../../pages/admin/services/modulo.service';
import { HttpCacheableService } from '../cachingService/http-cacheable.service';
import { Distributor } from '../../../pages/admin/models/distributor';

@Injectable({
  providedIn: 'root',
})
export class UserService implements NbRoleProvider {
  private apiAuthRoute: string;
  private apiTokenRoute: string;
  private apiUsuarioRoute: string;
  private accesos: number[] = [];
  private distribuidor: Distributor;

  email: string;
  userId: number;
  distribuidorId: number;
  paisDistribuidorId: number;
  moneda: string;
  pais: Pais;
  nombre: string;
  apellido: string;
  fotoPerfil: string;
  rol: string;
  telefono: string;
  direccion: string;
  ciudad: string;
  provincia: string;
  userAuth0: string;
  idioma: string;
  modulos: Modulo[];
  grupo: Grupo;

  constructor(
    private urlService: UrlService,
    private http: HttpClient,
    private httpCacheable: HttpCacheableService,
    private localStorageService: LocalstorageService,
    private cachingService: CachingService,
    private paisService: PaisService,
    private moduloService: ModuloService,) {
    this.apiAuthRoute = `${this.urlService.getBaseUrl()}Auth/`;
    this.apiTokenRoute = `${this.urlService.getBaseUrl()}Token/`;
    this.apiUsuarioRoute = `${this.urlService.getBaseUrl()}Usuarios/`;
  }

  setUsuario() {
    this.getLoggedUser().subscribe(usuario => {
      if (usuario && !usuario.pendienteDeAprobacion
        && !usuario.usuarioExistenteEnNexoSinPermisos
        && !usuario.usuarioDeOtraAplicacionNoExistenteEnNexo) {
        this.email = usuario.email;
        this.userId = usuario.id;
        this.nombre = usuario.nombre;
        this.apellido = usuario.apellido;
        this.rol = usuario.rol.nombre;
        this.fotoPerfil = usuario.fotoPerfil ? usuario.fotoPerfil : null;
        this.telefono = usuario.telefono ? usuario.telefono : null;
        this.direccion = usuario.direccion ? usuario.direccion : null;
        this.ciudad = usuario.ciudad ? usuario.ciudad : null;
        this.provincia = usuario.provincia ? usuario.provincia : null;
        this.distribuidorId = usuario.distribuidor ? usuario.distribuidor.id : null;
        this.paisDistribuidorId = usuario.distribuidor ? usuario.distribuidor.paisNodumId : null;
        this.accesos = usuario.accesos;
        this.distribuidor = usuario.distribuidor;
        // Se toma AR por defecto
        if (usuario && usuario.pais) {
          this.procesarDatosDelPais(usuario.pais, usuario.rol);
        } else {
          this.paisService.getPaisByCodigo(Config.Paises.ArgentinaCodigo).subscribe((data: Pais) => {
            this.procesarDatosDelPais(data, usuario.rol);
          });
        }
        sessionStorage.setItem(Config.LocalStorage.usuarioLogueado, this.getNombreApellido());
        sessionStorage.setItem(Config.LocalStorage.rolUsuarioLogueado, this.rol);
        sessionStorage.setItem(Config.LocalStorage.fotoPerfil, this.getFotoPerfil());
      } else if (usuario && (usuario.pendienteDeAprobacion
        || usuario.usuarioExistenteEnNexoSinPermisos
        || usuario.usuarioDeOtraAplicacionNoExistenteEnNexo)) {
        sessionStorage.setItem(Config.LocalStorage.redirect, usuario.redirect);
      }
      sessionStorage.setItem(Config.LocalStorage.usuarioSeteado, 'seteado');
    });
  }

  procesarDatosDelPais(pais: Pais, rol: Rol) {
    this.pais = pais;
    this.moneda = pais.moneda;
    this.idioma = pais.idioma;
    this.paisService.getGruposList().subscribe(data => {
      this.grupo = data.find(grupo => grupo.paises.length === 1 && grupo.paises[0].id === this.pais.id);
    })
    if (rol.nombre === Config.Roles.Distribuidor) {
      this.modulos = pais.modulos;
    } else {
      this.modulos = this.moduloService.getList();
    }
  }

  getRole(): Observable<string> {
    if (!this.rol) {
      return of(this.localStorageService.get(Config.LocalStorage.Dist) ? 
                Config.Roles.Distribuidor : sessionStorage.getItem(Config.LocalStorage.rolUsuarioLogueado) ? 
                sessionStorage.getItem(Config.LocalStorage.rolUsuarioLogueado) : 'Invitado');
    }
    return of(this.localStorageService.get(Config.LocalStorage.Dist) ? Config.Roles.Distribuidor : this.rol);
  }

  getEmail(): string {
    const user: Usuario = this.localStorageService.get(Config.LocalStorage.Dist);
    if (user) {
      return user.email;
    } else {
      return this.email;
    }
  }

  getDistribuidorId(): number {
    const user: Usuario = this.localStorageService.get(Config.LocalStorage.Dist);
    if (user) {
      return user.distribuidor.id;
    } else {
      return this.distribuidorId;
    }
  }

  getPaisId(): number {
    const user: Usuario = this.localStorageService.get(Config.LocalStorage.Dist);
    if (user) {
      return user.pais.id;
    } else {
      return this.pais ? this.pais.id : 0
    }
  }

  getPais(): Pais {
    const user: Usuario = this.localStorageService.get(Config.LocalStorage.Dist);
    if (user) {
      return user.pais;
    } else {
      return this.pais;
    }
  }

  getIdiomaDelUsuario(){
    return this.pais ? this.pais.idioma : 'es';
  }


  getGrupoId(): number {
    return this.grupo.id;
  }

  getGrupo(): Grupo {
    return this.grupo;
  }

  getPaisDistribuidorId(): number {
    const user: Usuario = this.localStorageService.get(Config.LocalStorage.Dist);
    if (user) {
      return user.distribuidor.paisNodumId;
    } else {
      return this.paisDistribuidorId;
    }
  }
  /**
   * Válido para las vistas de distribuidor o cuando el usuario tiene rol de distribuidor
   */
  getDistribuidor(): Distributor {
    const user: Usuario = this.localStorageService.get(Config.LocalStorage.Dist);
    if (user) {
      return user.distribuidor;
    } else {
      return this.distribuidor;
    }
  }

  getUserId(): number {
    const user: Usuario = this.localStorageService.get(Config.LocalStorage.Dist);
    if (user) {
      return user.id;
    } else {
      return this.userId;
    }
  }

  getMoneda(): string {
    const user: Usuario = this.localStorageService.get(Config.LocalStorage.Dist);
    if (user) {
      if (user.distribuidor.paisOrigenId == 4)
        return "USD"
      return user.pais.moneda;
    } else {
      return this.moneda;
    }
  }

  getMonedaMultiPaises(): Observable<Pais[]> {
    return this.paisService.getListMultiPaises(this.userId);
  }

  getNombreApellido(): string {
    return `${this.nombre} ${this.apellido}`;
  }

  getFotoPerfil(): string {
    const user: Usuario = this.localStorageService.get(Config.LocalStorage.Dist);
    if (user) {
      return this.createImgPath(user.fotoPerfil);
    } else {
      if (this.fotoPerfil) {
        return this.createImgPath(this.fotoPerfil);
      } else {
        return '';
      }
    }
  }

  hasRole(roles: string[]): boolean {
    let rol;
    this.getRole().subscribe(result => { rol = result; });
    return roles.length === 0 || roles.some(x => x === rol);
  }

  validateResetToken(token: string): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${this.apiTokenRoute}ValidateResetToken`, JSON.stringify(token), { headers: header });
  }

  recuperarContrasenia(email: string): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<any>(`${this.apiAuthRoute}RecuperarContrasenia/`, JSON.stringify(email), { headers: header });
  }

  crearContraseña(resetToken: string, password: string, confirmPassword: string): Observable<any> {
    return this.http.post(`${this.apiAuthRoute}CrearContrasenia/`, { resetToken, password, confirmPassword });
  }

  getUsuarioByDistId(distId: number): Observable<Usuario> {
    const key = `Usuario::getUsuarioByDistId::${distId}`;
    return this.cachingService.get(
      key,
      this.http.get<Usuario>(`${this.apiUsuarioRoute}getUsuarioByDistId/${distId}`), true);
  }

  getUsuarioByAuth0id(auth0Id: string): Observable<Usuario> {
    return this.http.get<Usuario>(`${this.apiUsuarioRoute}getUsuarioByAuth0id?auth0Id=${auth0Id}`);
  }

  getLoggedUser(): Observable<Usuario> {
      return this.http.get<Usuario>(`${this.apiUsuarioRoute}getLoggedUser`);
    }

  getCantidadDeUsuariosPendientesDeAprobacion(): Observable<number> {
    return this.httpCacheable.get<number>( { url: `${this.apiUsuarioRoute}getCantidadDeUsuariosPendientesDeAprobacion`, cacheSecs: 5 });
  }
  

  getUsuarioById(id: number): Observable<Usuario> {
    return this.http.get<Usuario>(`${this.apiUsuarioRoute}getUsuarioById?id=${id}`);
  }

  logSesionUsuario(auth0Id: string): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<string>(`${this.apiUsuarioRoute}logSesionUsuario`, JSON.stringify(auth0Id), { headers: header });
  }

  getModulosAutorizados(): Modulo[] {
    const user: Usuario = this.localStorageService.get(Config.LocalStorage.Dist);

    if (!this.modulos) this.modulos = this.moduloService.getList();

    let result: Modulo[];
    if (user) {
      result = user.pais.modulos;
    } else {
      result = this.modulos;
    }

    if (result && result.findIndex(m => m.id == 6) < 0) {
      result.push(this.moduloService.getList().find(m => m.id ==6));
    }
    return result;
  }

  tieneAcceso(opcionId: number) : boolean {
    return this.accesos?.includes(opcionId);
  }

  private createImgPath = (serverPath: string) => {
    const url = environment.apiEndPoint + `${serverPath}`;
    return url.replace(/\\/g, '/');
  }
}
