// send-message.service.ts
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class FilterChangeService {

  filterEvent = new EventEmitter<any>();

  eventoFiltradoGraficoRoles = new EventEmitter<any>();

  pendingCountEvent = new EventEmitter<any>();

  anioFiscalEvent = new EventEmitter<any>();

  constructor() { }
}
