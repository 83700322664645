import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class NavigationService {

    constructor(private router: Router) {
        this.router = router;
    }

    getCurrentUrl() {
        return this.router.url;
     }

    goToABMUsuarios() {
        this.router.navigate(['./pages/admin/usuarios']);
    }

    refreshUrl() {
        const url = this.getCurrentUrl();
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate([url]);
        });
    }

    gotToDashboardByDistId (distId: number) {
        this.router.navigate(['/pages/dashboard', distId]);
    }
}
