import { Component, OnInit } from '@angular/core';
import { NbMenuService } from '@nebular/theme';
import { Router } from '@angular/router';
import { UserService } from './@core/services/userService/user.service';
import { Config } from './@core/config/config';
import { LocalstorageService } from './@core/services/localStorageService/local-storage-service.service';
import { NavigationService } from './@core/services/navigationService/navigation.service';
import { CalendarioService } from './@core/services/calendarioService/calendario.service';
import { PaisService } from './@core/services/pais.service';


@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
    private menuService: NbMenuService,
    private localStorageService: LocalstorageService,
    private navigationService: NavigationService,
    private calendarioService: CalendarioService,
    private paisService: PaisService) {
      this.localStorageService.remove(Config.LocalStorage.Dist)
  }

  ngOnInit(): void {
    this.calendarioService.setAniosFiscales();
    this.menuService.onItemClick()
      .subscribe((event) => {
        this.onContextItemSelection(event.item.title);
      });
  }

  onContextItemSelection(title) {
    switch (title) {
      case 'Admin': {
        this.router.navigate(['/pages/admin']);
        break;
      }
      case 'Perfil': {
        this.router.navigate(['/pages/perfil']);
        break;
      }
      case 'Cerrar vista distribuidor': {
        this.localStorageService.remove(Config.LocalStorage.Dist);
        this.router.navigateByUrl('/pages/refresh').then(() =>
          this.router.navigate(['/pages/dashboard']));
        break;
      }
    }
  }
}
