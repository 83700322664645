import { Injectable } from '@angular/core';
import { UrlService } from '../urlService/url.service';
import { HttpClient } from '@angular/common/http';
import { HttpCacheableService } from '../cachingService/http-cacheable.service';


@Injectable({
  providedIn: 'root',
})
export class CalendarioService {

  aniosFiscalesList: number[];
  apiRoute: string;

  constructor(private urlService: UrlService,
    private http: HttpClient, private httpCacheable: HttpCacheableService) {
    this.apiRoute = `${this.urlService.getBaseUrl()}Calendario/`;
  }

  setAniosFiscales() {
    this.httpCacheable.get<number[]>({ url: `${this.apiRoute}GetAniosFiscales`, cacheSecs: 3600 }).subscribe(response => 
      this.aniosFiscalesList = response);
  }


  getAniosFiscales() {
    return this.aniosFiscalesList;
  }
}

