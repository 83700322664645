import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuItem, NbMenuService } from '@nebular/theme';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-language',
  template: `
      <button type="button" class="idioma" nbutton 
        [nbContextMenu]="idiomas" nbContextMenuTag='selector-idioma'
        [title]="idiomaActual" >
        <i class="fas fa-globe fa-lg"></i>
      </button>  
  `,
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  idiomaActual: string;
  idiomas: NbMenuItem[] =[
    { title: 'Español',  queryParams: { value:'es'}},
    { title: 'Português', queryParams: { value:'pt'}}
  ]

  constructor(private tanslocoService:TranslocoService, 
    private nbMenuService: NbMenuService) { }
  
  ngOnInit(): void {
    this.nbMenuService.onItemClick().pipe(      
      filter(({ tag }) => tag === 'selector-idioma'),
      map(({ item: { title, queryParams } }) => {
        return {id: queryParams.value, nombre: title}
      }),
      takeUntil(this.destroy$)
    ).subscribe(idioma => {
        this.change(idioma.id);
        this.idiomaActual = idioma.nombre;
      });

    const activeLang = this.tanslocoService.getActiveLang();
    this.idiomaActual = this.idiomas.find(x => x.queryParams.value === activeLang)?.title;    
  }

  ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }

  change(idioma:any){
    this.tanslocoService.setActiveLang(idioma);
  }
}
