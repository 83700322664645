import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, shareReplay } from 'rxjs/operators';

@Injectable()
export class CachingService {

    globalCache = {};
    billDependentCache = {};

    constructor() { }

    get<T>(key: string, callback: Observable<T>, skipCache: boolean = false): Observable<T> {
        return this.getFromCache(this.globalCache, key, callback, skipCache);
    }

    getBillDependent<T>(key: string, callback: Observable<T>, skipCache: boolean = false): Observable<T> {
        return this.getFromCache(this.billDependentCache, key, callback, skipCache);
    }

    getAndRemoveFromCache<T>(key: string, callback: Observable<T>, skipCache: boolean = false, keyToRemove: string): Observable<T> {
        if (!this.globalCache[key] || skipCache) {
            this.globalCache[key] = callback.pipe(
                shareReplay(1),
                catchError(err => {
                    delete this.globalCache[key];
                    return throwError(err);
                }),
                finalize(() => {
                    delete this.globalCache[keyToRemove];
                }));
        }

        return this.globalCache[key] as Observable<T>;
    }

    removeAllBillDependent() {
        this.billDependentCache = {};
    }

    private getFromCache<T>(cache: any, key: string, callback: Observable<T>, skipCache: boolean): Observable<T> {
        if (!cache[key] || skipCache) {
            cache[key] = callback.pipe(
                shareReplay(1),
                catchError(err => {
                    delete cache[key];
                    return throwError(err);
                }));
        }

        return cache[key] as Observable<T>;
    }
}
