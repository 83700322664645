import { environment } from '../../../../environments/environment';

export class UrlService {
  private apiRoute: string;

  constructor() {
    this.apiRoute = `${environment.apiEndPoint}api/`;
  }

  getBaseUrl(): string {
    return this.apiRoute;
  }
}
