import { Observable } from 'rxjs';

export interface Target {
  distribuidorId: number;
  categoriaProductoId: number;
  categoriaProductoNombre: string;
  unidadNegocioId: number;
  unidadNegocioNombre: string;
  mesIndex: number;
  objetivoId: number;
  objetivoValor: number;
}
export interface TargetQView {
  distribuidorId: number;
  categoriaProductoId: number;
  categoriaProductoNombre: string;
  unidadNegocioId: number;
  unidadNegocioNombre: string;
  mesIndex: number;
  targetAId: number;
  targetAMesIndex: number;
  targetBId: number;
  targetBMesIndex: number;
  targetCId: number;
  targetCMesIndex: number;
  targetValueA: number;
  targetValueB: number;
  targetValueC: number;
}
export interface QHeaderMonths {
  monthA: string;
  monthB: string;
  monthC: string;
}
export interface QHeader {
  Q1Header: QHeaderMonths;
  Q2Header: QHeaderMonths;
  Q3Header: QHeaderMonths;
  Q4Header: QHeaderMonths;
}
export interface TargetStatus {
  distribuidorId: number;
  estado: number;
}
export abstract class TargetData {
  abstract getList(distributorId: number): Observable<Target[]>;
  abstract getStatus(distributorId: number): Observable<TargetStatus>;
  abstract aproveTarget(distributorId: number): Observable<any>;
  abstract rejectTarget(distributorId: number): Observable<any>;
  abstract saveList(targets: Target[]): Observable<any>;
  abstract getHeaderMonths(lang: string): QHeader;
}
