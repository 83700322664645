import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
     <ng-container *transloco="let t; read: 'footer'">
     <span>
       {{ t('copyright') }}
     </span>
     </ng-container>
     `,
    })
export class FooterComponent {
}
