import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UrlService } from '../../../@core/services/urlService/url.service';
import { Modulo, ModuloData } from '../models/modulo';
import { TranslocoService } from '@ngneat/transloco'
@Injectable({
  providedIn: 'root'
})
export class ModuloService extends ModuloData implements OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  private apiRoute: string;
  modulos: Modulo[];
  private idioma: string;

  constructor(private urlService: UrlService, private http: HttpClient, i18nService: TranslocoService) {
    super();
    this.apiRoute = `${this.urlService.getBaseUrl()}Modulo/`;
    i18nService.langChanges$
        .pipe(takeUntil(this.destroy$))
        .subscribe( lang => {
          if(lang !== this.idioma || !this.modulos){
            this.idioma = lang;
            this.http.get<Modulo[]>(`${this.apiRoute}GetModulos`)
              .pipe(takeUntil(this.destroy$))
              .subscribe(modulos => {
                this.modulos = modulos;
              });
          }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getList(): Modulo[] {
    return this.modulos;
  }

  getModuloId(nombreModulo: string): number {
    const result = this.modulos?.find(m => m.nombreNormalizado === nombreModulo);
    if (result) return result.id;
    return 0;
  }
}
