import { Injectable } from '@angular/core';
import { Puesto } from '../models/puesto';
import { Observable } from 'rxjs';
import { UrlService } from '../../../@core/services/urlService/url.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PuestoService {
    private apiRoute: string;

    constructor(private urlService: UrlService, private http: HttpClient) {
        this.apiRoute = `${this.urlService.getBaseUrl()}Puesto/`;
    }

    getPuestos(): Observable<Puesto[]> {
        return this.http.get<Puesto[]>(`${this.apiRoute}GetPuestos`);
    }
}
