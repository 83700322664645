import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbPasswordAuthStrategy, NbAuthOAuth2JWTToken, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  SeoService,
  StateService,
} from './utils';
import { NotificationService } from './services/NotificationService/notification.service';
import { LocalstorageService } from './services/localStorageService/local-storage-service.service';
import { ExportExcelService } from './services/exportExcelService/export-excel.service';
import { UrlService } from './services/urlService/url.service';
import { HttpRequest } from '@angular/common/http';
import { environment} from '../../environments/environment';
import { NavigationService } from './services/navigationService/navigation.service';
import { CachingService } from './services/cachingService/caching.service';
import { PaisService } from './services/pais.service';

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: environment.apiEndPoint,
        login: {
          endpoint: 'api/auth/login',
          method: 'post',
        },
        logout: {
          endpoint: '',
        },
        token: {
          class: NbAuthOAuth2JWTToken,
          key: 'token',
        },
        refreshToken: {
          endpoint: 'api/token/refresh',
          method: 'post',
        },
      }),
    ],
    forms: {
      login: {
        strategy: 'email',
      },
      logout: {
        strategy: 'email',
      },
      validation: {
        password: {
          required: true,
          minLength: 8,
          maxLength: 50,
        },
      },
    },
  }).providers,
  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  SeoService,
  StateService,
  PaisService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
        UrlService,
        NavigationService,
        NotificationService,
        CachingService,
        LocalstorageService,
        ExportExcelService,
        { provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: function (req: HttpRequest<any>) {
          return req.url === environment.apiEndPoint + '/api/token/refresh';
          },
        },
      ],
    };
  }
}
