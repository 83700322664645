import { Injectable } from '@angular/core';
import { UrlService } from '../../../@core/services/urlService/url.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contacto } from '../models/contacto';

@Injectable()
export class ContactoService {
    private apiRoute: string;
    constructor(private urlService: UrlService, private http: HttpClient) {
        this.apiRoute = `${this.urlService.getBaseUrl()}Contacto/`;
    }

    getLista(): Observable<Contacto[]> {
        return this.http.get<Contacto[]>(`${this.apiRoute}GetContactos`);
    }

    update(formData: FormData, id: number): Observable<any>  {
        return this.http.post<any>(`${this.apiRoute}EditarContacto/${id}`, formData);
    }
}
