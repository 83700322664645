import { Observable } from 'rxjs';
import { Rol } from './rol';
import { Distributor } from './distributor';
import { UsuarioPadre } from './usuario-padre';
import { Pais } from './pais';

export class Usuario {
  id: number;
  nombre: string;
  apellido: string;
  email: string;
  rol: Rol;
  distribuidorId: number;
  distribuidor: Distributor;
  padre: UsuarioPadre;
  telefono: string;
  direccion: string;
  ciudad: string;
  provincia: string;
  pais: Pais;
  paises: Pais[];
  newPais: string;
  taxId: string;
  fotoPerfil: string;
  auth0Id: string;
  pendienteDeAprobacion: boolean;
  usuarioExistenteEnNexoSinPermisos: boolean;
  usuarioDeOtraAplicacionNoExistenteEnNexo: boolean;
  redirect: string;
  accesos: number[];  
}

export abstract class UsuarioData {
  abstract getList(): Observable<Usuario[]>;
}
