import { Observable } from 'rxjs';

export class BusinessUnit {
  id: number;
  codigo: string;
}

export abstract class BusinessUnitData {
  abstract getList(): Observable<BusinessUnit[]>;
}
