import { UrlService } from '../../../@core/services/urlService/url.service';
import { CachingService } from '../../../@core/services/cachingService/caching.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoriaArticulo } from '../models/categoria-articulo';

@Injectable()
export class CategoriaArticuloService {
  private apiRoute: string;

  constructor(private urlService: UrlService, private cachingService: CachingService, private http: HttpClient) {
    this.apiRoute = `${this.urlService.getBaseUrl()}CategoriaArticulo/`;
  }

  getCategoriasArticulos(): Observable<CategoriaArticulo[]> {
    return this.http.get<CategoriaArticulo[]>(`${this.urlService.getBaseUrl()}CategoriaArticulo/GetCategoriasArticulos`);
  }
}
