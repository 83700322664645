<ngx-landing-layout>
    <router-outlet>
        <ng-container *transloco="let t; scope: 'landing'">

            <head>
                <meta charset="utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1">
                Global site tag (gtag.js) - Google Analytics
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-TZ6MTC1NG8"></script>
                <script>
                    window.dataLayer = window.dataLayer || [];
                    function gtag() { dataLayer.push(arguments); }
                    gtag('js', new Date());

                    gtag('config', 'G-TZ6MTC1NG8');
                </script>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css"
                    rel="stylesheet" integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl"
                    crossorigin="anonymous">

                <title>BD Nexo</title>
            </head>

            <body>
                <div class="container-fluid">
                    <div class="row">
                        <div class="d-sm-none d-md-block col-md-2"></div>
                        <div class="col-md-8 col-xs-12" id="header">
                            <div class="container-fluid">
                                <div class="nav">
                                    <div class=" d-flex w-50 justify-content-start pt-3">
                                        <a class="nav-item" id="logo-BD" href="https://nexola.bd.com/" target="_blank"><img
                                                src="../../assets/images/landing/iconoBD.png" alt="Logo BD"></a>
                                    </div>
                                    <div class="d-flex w-50 justify-content-end pt-3">
                                        <a id="logo-Nexo" href="https://nexola.bd.com/"><img height="45"
                                                src="../../assets/images/landing/iconoNexo.png" alt="Logo Nexo"></a>
                                        <ngx-language></ngx-language>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-sm-none col-md-2"></div>
                    </div>
                    <div class="row">
                        <div class="d-sm-none d-md-block col-md-2"></div>
                        <div class="col-md-8 col-xs-12 justify-content-center " id="welcome">
                            <p id="welcome-text" class="text-white align-self-center">{{t('landing.bienvenidos')}}</p>
                        </div>
                        <div class="d-sm-none col-md-2"></div>
                    </div>
                    <div class="row mt-3">
                        <div class="d-sm-none d-md-block col-md-2"></div>
                        <div class="col-md-8 col-xs-12" id="hello">
                            <p class="h2 text-center mt-4">{{t('landing.hola')}}</p>
                            <div class="row">

                                <div class="col-xs-12" id="">
                                    <div class="container-fluid">
                                        <p class="d-none d-md-block text-center hello-text h4">
                                            {{t('landing.nexo-descripcion-1')}}<br>
                                            {{t('landing.nexo-descripcion-2')}}<span
                                                class="bold">{{t('landing.nexo-descripcion-3')}}</span>
                                            {{t('landing.nexo-descripcion-4')}}
                                            <span class="bold"> {{t('landing.nexo-descripcion-5')}}</span><br>
                                            {{t('landing.nexo-descripcion-6')}} <span
                                                class="bold">{{t('landing.nexo-descripcion-7')}}</span>
                                            {{t('landing.nexo-descripcion-8')}}
                                        </p>
                                        <p class="d-block d-md-none  text-center hello-text h4">
                                            {{t('landing.nexo-descripcion-1')}}<br>
                                            {{t('landing.nexo-descripcion-2')}}<span
                                                class="bold">{{t('landing.nexo-descripcion-3')}}</span>
                                            {{t('landing.nexo-descripcion-4')}}
                                            <span class="bold"> {{t('landing.nexo-descripcion-5')}}</span><br>
                                            {{t('landing.nexo-descripcion-6')}} <span
                                                class="bold">{{t('landing.nexo-descripcion-7')}}</span>
                                            {{t('landing.nexo-descripcion-8')}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <p class="text-center"></p>
                        </div>
                        <div class="d-sm-none col-md-2"></div>
                    </div>
                    <div class="row mt-4">
                        <div class="d-none d-md-block col-md-2"></div>
                        <div class="col-md-8 col-sm-12" id="ask-user">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-5">
                                        <p class="h1_5  w-100 mt-4">{{t('landing.ingresar-portal-1')}} <b>
                                                {{t('landing.ingresar-portal-2')}}</b> </p>
                                        <p class="h5 ">
                                            {{t('landing.ingresar-portal-3')}}<b>{{t('landing.ingresar-portal-4')}}</b>
                                        </p>
                                        <p class="h5 "> <b>{{t('landing.ingresar-portal-5')}}</b> </p>
                                    </div>


                                    <div class="col-xs-12 col-sm-7 text-center">
                                        <div class="margin-video">
                                            <div class="video-responsive">
                                                <iframe class="embed-responsive-item"
                                                    src="https://www.youtube.com/embed/675K7MRGFDM"
                                                    title="YouTube video player" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen>
                                                </iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="d-none d-md-blockcol-md-2"></div>
                    </div>
                    <div class="row">
                        <div class="d-none d-md-block col-md-2"></div>
                        <div class="col-md-8 col-sm-12" id="new-login-method">

                            <div class="col-12 text-center">
                                <p class="h2 mt-4">{{t('landing.modo-ingreso-1')}}</p>
                                <p class="h4 text-grayish">{{t('landing.modo-ingreso-2')}}</p>
                                <div class="row">
                                    <div class="col-xs-12 col-md-6 mt-2">
                                        <div class="row ">
                                            <div class="col-xs-1 col-md-2"></div>
                                            <div class="col-xs-11 col-md-10">
                                                <table class="table mt-2 h5 table-text">
                                                    <thead>
                                                        <tr class="orange-back">
                                                            <th class="h4 text-white text-center" scope="col">
                                                                {{t('landing.modo-ingreso-3')}}<span
                                                                    class="underlined bold">{{t('landing.modo-ingreso-4')}}</span>
                                                                {{t('landing.modo-ingreso-5')}}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class="dark-gray">

                                                            <td>1. {{t('landing.modo-ingreso-6')}}</td>

                                                        </tr>
                                                        <tr class="light-gray">

                                                            <td>2. {{t('landing.modo-ingreso-7')}}</td>

                                                        </tr>
                                                        <tr class="dark-gray">

                                                            <td>3. {{t('landing.modo-ingreso-8')}}</td>

                                                        </tr>
                                                        <tr class="light-gray">

                                                            <td>4. {{t('landing.modo-ingreso-9')}}</td>

                                                        </tr>
                                                        <tr class="dark-gray">

                                                            <td>5. {{t('landing.modo-ingreso-10')}}</td>

                                                        </tr>
                                                        <tr class="light-gray">

                                                            <td>6. {{t('landing.modo-ingreso-11')}}
                                                            </td>

                                                        </tr>
                                                        <tr class="light-gray">

                                                            <td>7. {{t('landing.modo-ingreso-16')}}
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6 mt-2">
                                        <div class="row ">

                                            <div class="col-xs-11 col-md-10">
                                                <table class="table mt-2 h5 table-text">
                                                    <thead>
                                                        <tr class="orange-back">
                                                            <th class="h4 text-white  text-center" scope="col">
                                                                {{t('landing.modo-ingreso-3')}} <span
                                                                    class="underlined bold">{{t('landing.modo-ingreso-12')}}</span>
                                                                {{t('landing.modo-ingreso-5')}}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class="dark-gray">

                                                            <td>1. {{t('landing.modo-ingreso-6')}}</td>

                                                        </tr>
                                                        <tr class="light-gray">

                                                            <td>2. {{t('landing.modo-ingreso-13')}}</td>

                                                        </tr>
                                                        <tr class="dark-gray">

                                                            <td>3. {{t('landing.modo-ingreso-14')}}</td>

                                                        </tr>
                                                        <tr class="light-gray">

                                                            <td>4. {{t('landing.modo-ingreso-9')}}</td>

                                                        </tr>
                                                        <tr class="dark-gray">

                                                            <td>5. {{t('landing.modo-ingreso-15')}}
                                                                <br>
                                                                <br>
                                                                <span class="larger-screen-br"><br> </span>
                                                            </td>


                                                        </tr>
                                                        <tr class="dark-gray">
                                                            <td><br></td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-2"></div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="d-none d-md-block col-md-2"></div>
                    </div>
                    <div class="row mt-2">
                        <div class="d-none d-md-block col-md-2"></div>
                        <div class="col-md-8 col-sm-12" id="invitation">
                            <p class="h1 w-100 mt-4 text-center text-white mb-4">{{t('landing.invitacion-ingreso')}}
                            </p>
                            <div class="row">
                                <div class="col-xs-1 col-md-2"></div>
                                <div class=" col-xs-11 col-md-4 border-right-white">
                                    <p class="h6-landing text-white text-center height-23">{{t('landing.si-eres')}}
                                        Argentina, Uruguay o Paraguay {{t('landing.haz-click')}}</p>
                                    <div class="w-100 d-flex justify-content-center">
                                        <a href="https://portalclientes.bd.com/es-ar"><button type="button"
                                                class="btn  text-center mt-2"
                                                id="link-portal">{{t('landing.ingresar-portal')}}</button></a>
                                    </div>
                                </div>
                                <div class="d-xs-block d-sm-none"><br> </div>
                                <div class=" col-xs-11 col-md-4">
                                    <p class="h6-landing text-white text-center height-23 ">{{t('landing.si-eres')}}
                                        Chile o Bolivia {{t('landing.haz-click')}} <span class="larger-screen-br"><br> </span></p>
                                    <div class="w-100 d-flex  justify-content-center">
                                        <a href="https://portalclientes.bd.com/es-cl"><button type="button"
                                                class="btn  text-center mt-2"
                                                id="link-portal">{{t('landing.ingresar-portal')}}</button></a>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-xs-1 col-md-2"></div>
                                <div class=" col-xs-11 col-md-4 border-right-white">
                                    <p class="h6-landing text-white text-center height-23">{{t('landing.si-eres')}}
                                        Colombia {{t('landing.haz-click')}} </p>
                                    <div class="w-100 d-flex justify-content-center">
                                        <a href="https://portalclientes.bd.com/es-co"><button type="button"
                                                class="btn  text-center mt-2"
                                                id="link-portal">{{t('landing.ingresar-portal')}}</button></a>
                                    </div>
                                </div>
                                <div class="d-xs-block d-sm-none"><br> </div>
                                <div class=" col-xs-11 col-md-4">
                                    <p class="h6-landing text-white text-center height-23">{{t('landing.si-eres')}} Perú
                                        {{t('landing.haz-click')}} <span class="larger-screen-br"><br> </span></p>
                                    <div class="w-100 d-flex  justify-content-center">
                                        <a href="https://portalclientes.bd.com/es-pe"><button type="button"
                                                class="btn  text-center mt-2"
                                                id="link-portal">{{t('landing.ingresar-portal')}}</button></a>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-xs-1 col-md-2"></div>
                                <div class=" col-xs-11 col-md-4 border-right-white">
                                    <p class="h6-landing text-white text-center height-23">{{t('landing.si-eres')}}
                                        México o {{t('landing.haz-click')}} <span class="larger-screen-br"><br> </span>
                                    </p>
                                    <div class="w-100 d-flex  justify-content-center">
                                        <a href="https://portalclientes.bd.com/es-mx"><button type="button"
                                                class="btn  text-center mt-2"
                                                id="link-portal">{{t('landing.ingresar-portal')}}</button></a>
                                    </div>
                                </div>
                                <div class="d-xs-block d-sm-none"><br> </div>
                                <div class=" col-xs-11 col-md-4">
                                    <p class="h6-landing text-white text-center height-23">{{t('landing.si-eres')}}
                                        Brasil {{t('landing.haz-click')}} <span class="larger-screen-br"><br> </span>
                                    </p>
                                    <div class="w-100 d-flex  justify-content-center">
                                        <a href="https://portalclientes.bd.com/pt-br"><button type="button"
                                                class="btn  text-center mt-2"
                                                id="link-portal">{{t('landing.ingresar-portal')}}</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="d-sm-none d-md-block col-md-2"></div>
                        <div class="col-md-8 col-sm-12" id="doubts">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="d-block d-md-none col-sm-1"></div>
                                    <div class="col-sm-10 col-md-6">
                                        <div class="d-none d-md-block">
                                            <p class="h2 pf">{{t('landing.tienes-dudas-1')}}</p>
                                            <p class="pf">{{t('landing.tienes-dudas-2')}}
                                            </p>
                                            <a href=" https://www.youtube.com/channel/UCa9JKLKIyDOxf2fVtfN71xg"
                                                class="btn black-border mf"
                                                target="_blank">{{t('landing.videos')}}</a>
                                        </div>
                                        <div class="d-block d-md-none">
                                            <p class="h2 ">{{t('landing.tienes-dudas-1')}}</p>
                                            <p>{{t('landing.tienes-dudas-2')}}
                                            </p>
                                            <a href=" https://www.youtube.com/channel/UCa9JKLKIyDOxf2fVtfN71xg"
                                                class="btn black-border" target="_blank">{{t('landing.videos')}}</a>
                                        </div>

                                    </div>
                                    <div class="col-sm-1 col-md-6"></div>
                                </div>
                            </div>
                        </div>
                        <div class="d-sm-none col-md-2"></div>
                    </div>
                    <div class="row">
                        <div class="d-sm-none d-md-block col-md-2"></div>
                        <div class="col-md-8 col-sm-12" id="footer">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="d-none d-lg-flex">
                                        <div class="col-sm-12 col-lg-3">
                                            <p class="h6-landing  pxf pt-3">{{t('landing.contactanos')}}:</p>
                                        </div>
                                        <div class="col-sm-12 col-lg-4">
                                            <a href="mailto:hola@bdnexo.com" class="w-100 d-flex text-black pt-3 "><img
                                                    src="../../assets/images/landing/mail_b.jpg" class="icon-footer-lg"
                                                    alt="email">
                                                <p class="h6-landing ">Email: hola@bdnexo.com</p>
                                            </a>
                                        </div>
                                        <div class="col-sm-12 col-lg-4">
                                            <a href="https://wa.link/gr7wq0" class="w-100 d-flex text-black pt-3"><img
                                                    src="../../assets/images/landing/whatsapp_b.jpg"
                                                    class="icon-footer-lg" alt="whatsapp">
                                                <p class="h6-landing  ">Whatsapp: + 54911.5243.8765</p>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="d-block d-lg-none">
                                        <div class="col-sm-12 col-lg-3">
                                            <p class="h6-landing   ptf">{{t('landing.contactanos')}}</p>
                                        </div>
                                        <div class="col-sm-12 col-lg-4">
                                            <a href="mailto:hola@bdnexo.com" class="w-100 d-flex ptf text-black"><img
                                                    src="../../assets/images/landing/mail_b.jpg"
                                                    class="icon-footer align-self-baseline" alt="email">
                                                <p class="h6-landing  align-self-center">Email: hola@bdnexo.com</p>
                                            </a>
                                        </div>
                                        <div class="col-sm-12 col-lg-4">
                                            <a href="https://wa.link/gr7wq0" target="_blank"
                                                class="w-100 d-flex text-black ptf"><img
                                                    src="../../assets/images/landing/whatsapp_b.jpg"
                                                    class="icon-footer align-self-baseline" alt="whatsapp">
                                                <p class="h6-landing  align-self-center">Whatsapp: + 54911.5243.8765</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-sm-none col-md-2"></div>
                    </div>
                </div>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-b5kHyXgcpbZJO/tY9Ul7kGkf1S0CWuKcCD38l8YkeH8z8QjE0GmW1gYU5S9FOnJ0"
                    crossorigin="anonymous"></script>
            </body>
        </ng-container>
    </router-outlet>
</ngx-landing-layout>
