<ngx-landing-layout>
    <router-outlet>
        <ng-container *transloco="let t; scope: 'activacionusuario'">
            <div class="container-fluid">
                <div class="row">
                    <div class="d-sm-none d-md-block col-md-2"></div>
                    <div class="col-md-8 col-xs-12" id="header">
                        <div class="container-fluid">
                            <div class="nav">
                                <div class=" d-flex w-50 justify-content-start pt-3">
                                    <a class="nav-item" id="logo-BD" href="https://nexola.bd.com/" target="_blank"><img
                                            src="../../assets/images/landing/iconoBD.png" alt="Logo BD"></a>
                                </div>
                                <div class="d-flex w-50 justify-content-end pt-3">
                                    <a id="logo-Nexo" href="https://nexola.bd.com/"><img height="45"
                                            src="../../assets/images/landing/iconoNexo.png" alt="Logo Nexo"></a>
                                    <ngx-language></ngx-language>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-sm-none col-md-2"></div>
                </div>
                <!--<div class="row">
                <div class="d-sm-none d-md-block col-md-2"></div>
                <div class="col-md-8 col-xs-12 justify-content-center " id="welcome">
                    <p id="welcome-text" class="text-white align-self-center">Bienvenidos</p>
                </div>
                <div class="d-sm-none col-md-2"></div>
            </div>-->
                <div class="row">
                    <div class="d-sm-none d-md-block col-md-2"></div>
                    <div class="col-md-8 col-xs-12" id="hello">
                        <p class="h2 text-center text-gold mt-4">{{t('activacionusuario.bienvenido')}}</p>
                        <div class="row">

                            <div class="col-xs-12" id="">
                                <div class="container-fluid">
                                    <p class="d-none d-md-block text-center hello-text h4">
                                        {{t('activacionusuario.intento-ingreso')}}
                                    </p>
                                    <p class="d-block d-md-none  text-center hello-text h4">
                                        {{t('activacionusuario.intento-ingreso')}}
                                    </p>
                                </div>
                            </div>
                            <br>
                            <div class="w-100 d-flex justify-content-center">
                                <a href="https://portalclientes.bd.com/{{redirect}}"><button type="button"
                                        class="btn btn-dark text-center"
                                        id="link-portal">{{t('activacionusuario.volver-portal')}}</button></a>
                            </div>

                        </div>
                        <p class="text-center"></p>
                    </div>
                    <div class="d-sm-none col-md-2"></div>
                </div>
                <div class="row">

                    <div class="d-none d-md-block col-md-2"></div>
                    <div class="d-none d-md-blockcol-md-2"></div>
                </div>
                <div class="row">
                    <div class="d-sm-none d-md-block col-md-2"></div>
                    <div class="d-sm-none col-md-2"></div>
                </div>
                <div class="row">
                    <div class="d-sm-none d-md-block col-md-2"></div>
                    <div class="col-md-8 col-sm-12" id="footer">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="d-none d-lg-flex">
                                    <div class="col-sm-12 col-lg-3">
                                        <p class="h6 text-black pxf pt-3">{{t('activacionusuario.contactanos')}}:</p>
                                    </div>
                                    <div class="col-sm-12 col-lg-4">
                                        <a href="mailto:hola@bdnexo.com" class="w-100 d-flex pt-3 "><img
                                                src="../../assets/images/landing/mail_b.jpg" class="icon-footer-lg"
                                                alt="email">
                                            <p class="h6 text-black">Email: hola@bdnexo.com</p>
                                        </a>
                                    </div>
                                    <div class="col-sm-12 col-lg-4">
                                        <a href="https://wa.link/3vpymm" class="w-100 d-flex  pt-3"><img
                                                src="../../assets/images/landing/whatsapp_b.jpg" class="icon-footer-lg"
                                                alt="whatsapp">
                                            <p class="h6 text-black ">Whatsapp: <br> + 54911.3181.6730</p>
                                        </a>
                                    </div>
                                </div>
                                <div class="d-block d-lg-none">
                                    <div class="col-sm-12 col-lg-3">
                                        <p class="h6 text-black  ptf">{{t('activacionusuario.contactanos')}}:</p>
                                    </div>
                                    <div class="col-sm-12 col-lg-4">
                                        <a href="mailto:hola@bdnexo.com" class="w-100 d-flex ptf "><img
                                                src="../../assets/images/landing/email.png"
                                                class="icon-footer align-self-baseline" alt="email">
                                            <p class="h6 text-black align-self-center">Email: hola@bdnexo.com</p>
                                        </a>
                                    </div>
                                    <div class="col-sm-12 col-lg-4">
                                        <a href="https://wa.link/3vpymm" target="_blank" class="w-100 d-flex ptf"><img
                                                src="../../assets/images/landing/whatsapp.png"
                                                class="icon-footer align-self-baseline" alt="whatsapp">
                                            <p class="h6 text-black align-self-center">Whatsapp: <br> + 54911.3181.6730
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-sm-none col-md-2"></div>
                </div>
            </div>
        </ng-container>
    </router-outlet>
</ngx-landing-layout>
