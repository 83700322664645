import { Observable } from 'rxjs';
import { Grupo } from './grupo';
import { Modulo } from './modulo';

export class Pais {
    id: number;
    codigo: string;
    grupoDisplay: string
    nombre: string;
    moneda: string;
    idioma: string;
    tipoCambio: number;
    descuentoCategoria: boolean;
    mostrarCategoria: boolean;
    autoAprobacionInventario: boolean;
    autoAprobacionSellOut: boolean;
    modulos: Modulo[];
    grupos: Grupo[];
}
export abstract class PaisData {
    abstract getList():  Observable<Pais[]>;
}
