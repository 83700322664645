import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario, UsuarioData } from '../models/usuario';
import { UrlService } from '../../../@core/services/urlService/url.service';
import { CacheService } from '../../../@core/services/cachingService/cache.service';
import { HttpCacheableService } from '../../../@core/services/cachingService/http-cacheable.service';

@Injectable()
export class UsuarioService extends UsuarioData {
  private apiRoute: string;

  constructor(private urlService: UrlService, private http: HttpClient,
              private cacheService: CacheService,
              private httpCacheable: HttpCacheableService,
               ) {
    super();
    this.apiRoute = `${this.urlService.getBaseUrl()}Usuarios/`;
  }

  getList(): Observable<Usuario[]> {
    return this.httpCacheable.get<Usuario[]>({ url: `${this.apiRoute}GetUsuariosActivos/`, cacheSecs: 5});
  }

  getPendingApprovalUsersAmount(): Observable<Number> {
    return this.http.get<Number>(`${this.apiRoute}GetCantidadDeUsuariosPendientesDeAprobacion/`);
  }

  create(formDataUsuario: FormData): Observable<any> {
    this.cacheService.remove(`${this.apiRoute}GetCantidadDeUsuariosPendientesDeAprobacion`);
    this.cacheService.remove(`${this.apiRoute}getusuariosactivos/`);
    return this.http.post<any>(`${this.apiRoute}CrearUsuario/`, formDataUsuario);
  }

  update(formDataUsuario: FormData): Observable<any> {
    this.cacheService.remove(`${this.apiRoute}getusuariosactivos/`);
    return this.http.post<any>(`${this.apiRoute}EditarUsuario/`, formDataUsuario);
  }

  delete(usuario): Observable<any> {
    this.cacheService.remove(`${this.apiRoute}getusuariosactivos/`);
    return this.http.post<any>(`${this.apiRoute}EliminarUsuario/`, usuario);
  }

  uploadFoto(id: number, formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiRoute}UploadFoto/${id}`, formData);
  }

  getUsuariosPendientesDeAprobacion(): Observable<Usuario[]> {
    return this.httpCacheable.get<Usuario[]>({ url: `${this.apiRoute}GetUsuariosPendientesDeAprobacion/`, cacheSecs: 5});
  }

}
