import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { Config } from '../config/config';
import { UserService } from '../services/userService/user.service';
import { environment as env } from '../../../environments/environment';

function until(conditionFunction) {

  const poll = resolve => {
    if (conditionFunction()) resolve();
    else setTimeout(_ => poll(resolve), 200);
  }

  return new Promise(poll);
}

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

  userService: UserService;
  rol: string;

  constructor(private router: Router, userService: UserService) {
    this.userService = userService;

    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('token');

    if (token == null || token == undefined || token.length == 0) {
      token = sessionStorage.getItem(Config.LocalStorage.auth0Token)
    }

    sessionStorage.clear();

    sessionStorage.setItem(Config.LocalStorage.auth0Token, token);

    try {
      let email = ''; 
      const getEmail = fetch(env.urlSSO + 'GetChangeUser', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          return data;
        })
        .catch((ex) => {
          sessionStorage.clear();
          this.router.navigateByUrl('/bienvenido');
        });

      getEmail.then((resEmail) => {
        email = resEmail.email

        const getUser = fetch(env.urlSSO + 'Get?email=' + email, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then((response) => response.json())
        .then((data) => {
          return data;
        })
        .catch((ex) => {
          sessionStorage.clear();
          this.router.navigateByUrl('/bienvenido');
        });;

        getUser.then((resUser) => {
          sessionStorage.setItem(Config.LocalStorage.auth0Id, resUser.id);

          this.userService.setUsuario();

          this.userService.getRole()
            .subscribe(rol => {
              this.rol = rol;
            });
        });
      });
    }
    catch (error) {
      sessionStorage.clear();
      this.router.navigateByUrl('/bienvenido');
    }
  }

  async canAccess(roles: string[], acceso?: number) {
    let token = sessionStorage.getItem(Config.LocalStorage.auth0Token)

    const getEmail = fetch(env.urlSSO + 'GetChangeUser', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

    getEmail.then((resEmail) => {
      if (resEmail.code != 200) {
        token = ''
      }           
    });

    await until(_ => sessionStorage.getItem(Config.LocalStorage.usuarioSeteado));

    if (token != null && token != undefined && token.length > 0) {
      if (!sessionStorage.getItem(Config.LocalStorage.usuarioLogueado)) {
        sessionStorage.clear();
        this.router.navigateByUrl('/activar-usuario');
        return false;
      }
      if (!this.userService.hasRole(roles) || (acceso && !this.userService.tieneAcceso(acceso))) {
        sessionStorage.clear();
        this.router.navigateByUrl('/');
        return false;
      }
      return true;
    }
    else {
      sessionStorage.clear();
      this.router.navigateByUrl('/bienvenido');
      return false;
    } 
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return route.data.acceso ? this.canAccess(route.data.roles, route.data.acceso) : this.canAccess(route.data.roles);
  }

  canLoad(route: Route) {
    return route.data.acceso ? this.canAccess(route.data.roles, route.data.acceso) : this.canAccess(route.data.roles);
  }
}
