import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BusinessUnit, BusinessUnitData } from '../models/business-unit';
import { UrlService } from '../../../@core/services/urlService/url.service';

@Injectable()
export class BusinessUnitService extends BusinessUnitData {
  private apiRoute: string;
  constructor(private urlService: UrlService, private http: HttpClient) {
      super();
      this.apiRoute = `${this.urlService.getBaseUrl()}Sellin/`;
  }

  getList(): Observable<BusinessUnit[]>  {
    return this.http.get<BusinessUnit[]>(`${this.apiRoute}GetUnidadesNegocio`);
  }

  getListByDistribuidorId(distribuidorId: number): Observable<BusinessUnit[]>  {
    return this.http.get<BusinessUnit[]>(`${this.apiRoute}GetUnidadesNegocioByDistribuidorId`, {params: {distribuidorId: distribuidorId.toString()}});
  }
}
