import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Integrante } from '../models/integrante';
import { UrlService } from '../../../@core/services/urlService/url.service';

@Injectable()
export class IntegranteService  {
  private apiRoute: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this.apiRoute = `${this.urlService.getBaseUrl()}Integrantes/`;
  }

  getIntegrantesByDistribuidorId(distribuidorId: number): Observable<Integrante[]>  {
    return this.http.get<Integrante[]>(`${this.apiRoute}GetIntegrantesByDistribuidorId`, {params: {distribuidorId: distribuidorId.toString()}});
  }

  create(integrante: Integrante): Observable<any>  {
    return this.http.post<any>(`${this.apiRoute}CrearIntegrante/`, integrante);
  }

  update(integrante: Integrante): Observable<any>  {
    return this.http.post<any>(`${this.apiRoute}EditarIntegrante/`, integrante);
  }

  delete(integrante: Integrante): Observable<any>  {
    return this.http.post<any>(`${this.apiRoute}EliminarIntegrante/`, integrante);
  }

  uploadFoto(id: number, formData: FormData): Observable<any>  {
    return this.http.post<any>(`${this.apiRoute}UploadFoto/${id}`, formData);
  }
}
