import { Injectable }  from '@angular/core'

@Injectable()
export class CacheService {
    constructor() { 
        const numeroDeVersion = '3';
        const version = localStorage.getItem('cache_version');
        if(!version || version !== numeroDeVersion) {
            this.cleanLocalStorage();
            localStorage.setItem('cache_version', numeroDeVersion);
        }
    }

    save(options: LocalStorageSaveOptions) {        
        options.expiraEnSegundos = options.expiraEnSegundos ?? 0;

        const expiraMiliSegundos = this.calculaExpiracionEnMilisegundos(options.expiraEnSegundos);

        const cacheItem = {
            value: typeof options.data === 'string' ? options.data : JSON.stringify(options.data),
            expira: expiraMiliSegundos !== 0 ? new Date().getTime() + expiraMiliSegundos : null,
            tieneExpiracion: (expiraMiliSegundos !== 0)
        }
        localStorage.setItem(options.key.toLowerCase(), JSON.stringify(cacheItem));
    }

    load(key: string) {
        const item = localStorage.getItem(key.toLowerCase());                
        if(item != null) {
            const cacheItem = JSON.parse(item);
            const now = new Date().getTime();
            if(!cacheItem || (cacheItem.tieneExpiracion && cacheItem.expira <= now)) {
                return null;
            } else {
                return JSON.parse(cacheItem.value);
            }
        }
        return null;
    }

    remove(key: string) {
        localStorage.removeItem(key.toLowerCase());
    }

    cleanLocalStorage() {
        localStorage.clear();
    }

    private calculaExpiracionEnMilisegundos(expiraEnSegundos: number): number{
        return expiraEnSegundos > 0 ? expiraEnSegundos * 1000 : 0;
    }
}

export class LocalStorageSaveOptions {
    key: string
    data: any
    expiraEnSegundos?: number
}