import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbMenuItem } from '@nebular/theme';
import { NbAccessChecker } from '@nebular/security';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LocalstorageService } from '../../../@core/services/localStorageService/local-storage-service.service';
import { Config } from '../../../@core/config/config';
import { Usuario } from '../../../pages/admin/models/usuario';
import { UserService } from '../../../@core/services/userService/user.service';
import { AprobacionSellOutService } from '../../../pages/admin/services/aprobacion-sell-out.service';
import { TargetService } from '../../../pages/admin/services/target.service';
import { FilterChangeService } from '../../../pages/dashboard/services/filter-change.service';
import { TranslocoService } from '@ngneat/transloco';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  usuarioLogueado = '';
  rolUsuarioLogueado = '';
  // Si el string está vacío se muestran las iniciales, si no, la foto de perfil.
  fotoPerfil: string;
  usuarioVistaDistribuidor: Usuario;
  pendingCount = 0;
  dashboardTooltip: string;

  themes = [
    {
      value: 'default',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  userMenu = [
    {
      title: 'Perfil',
      data: {
        permission: 'view',
        resource: 'perfil',
      },
    },
    {
      title: 'Admin',
      data: {
        permission: 'view',
        resource: 'admin-module',
      },
    },
    /*{
      title: 'Cerrar sesión',
      data: {
        permission: 'view',
        resource: 'dashboard',
      },
    },*/
  ];

  userMenuVistaDistribuidor = [
    {
      title: 'Perfil',
      data: {
        resource: 'perfil',
      },
    },
    {
      title: 'Cerrar vista distribuidor',
      data: {
        resource: 'cerrar-vista-distribuidor',
      },
    },
  ];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private accessChecker: NbAccessChecker,
    private localStorageService: LocalstorageService,
    private userService: UserService,
    private selloutService: AprobacionSellOutService,
    private objetivoService: TargetService,
    private filterChangeService: FilterChangeService,
    private i18nService: TranslocoService) {
    i18nService.selectTranslateObject('menu')
      .pipe(takeUntil(this.destroy$))
      .subscribe(i18n => {
        this.dashboardTooltip = i18n.dashboardTooltip;
      });

  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.usuarioLogueado = sessionStorage.getItem(Config.LocalStorage.usuarioLogueado);
    this.rolUsuarioLogueado = sessionStorage.getItem(Config.LocalStorage.rolUsuarioLogueado);
    this.getPendingCount();
    this.fotoPerfil = sessionStorage.getItem(Config.LocalStorage.fotoPerfil);
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
      
    this.authMenuItems();
    this.filterChangeService.pendingCountEvent
      .pipe(takeUntil(this.destroy$))
      .subscribe(msg => {
        this.getPendingCount();
      });
    this.i18nService.setActiveLang(this.userService.getIdiomaDelUsuario());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  borrarStorage() {
    this.localStorageService.remove(Config.LocalStorage.Dist);
  }

  checkLocalStorage(): boolean {
    let isVistaDistribuidor = false;
    if (this.localStorageService.get(Config.LocalStorage.Dist)) {
      this.usuarioVistaDistribuidor = this.localStorageService.get(Config.LocalStorage.Dist);
      isVistaDistribuidor = true;
    }
    return isVistaDistribuidor;
  }

  authMenuItems() {
    this.userMenu.forEach(item => {
      this.authMenuItem(item);
    });
  }

  authMenuItem(menuItem: NbMenuItem) {
    if (menuItem.data && menuItem.data['permission'] && menuItem.data['resource']) {
      this.accessChecker.isGranted(menuItem.data['permission'], menuItem.data['resource'])
        .pipe(takeUntil(this.destroy$))
        .subscribe(granted => {
          menuItem.hidden = !granted;
        });
    } else {
      menuItem.hidden = true;
    }
    if (!menuItem.hidden && menuItem.children != null) {
      menuItem.children.forEach(item => {
        if (item.data && item.data['permission'] && item.data['resource']) {
          this.accessChecker.isGranted(item.data['permission'], item.data['resource'])
            .pipe(takeUntil(this.destroy$))
            .subscribe(granted => {
              item.hidden = !granted;
            });
        } else {
          // if child item do not config any `data.permission` and `data.resource` just inherit parent item's config
          item.hidden = menuItem.hidden;
        }
      });
    }
  }

  getPendingCount() {
    this.pendingCount = 0;
    this.userService.getRole()
      .pipe(takeUntil(this.destroy$))
      .subscribe(rol => {
        if (rol == Config.Roles.Administrador) {
          this.userService.getCantidadDeUsuariosPendientesDeAprobacion()
            .pipe(takeUntil(this.destroy$))
            .subscribe(count =>
              this.pendingCount += count);
          this.selloutService.getCantidadSelloutPendientesDeAprobacion()
            .pipe(takeUntil(this.destroy$))
            .subscribe(count =>
              this.pendingCount += count);
        }

        if (rol === Config.Roles.Gerente || rol === Config.Roles.Administrador || rol === Config.Roles.SuperGerente) {
          this.objetivoService.getCantidadObjetivosPendientesDeAprobacion()
            .pipe(takeUntil(this.destroy$))
            .subscribe(count =>
              this.pendingCount += count);
        }
      });
  }
}
