import { Injectable } from '@angular/core';
import { UrlService } from '../../../@core/services/urlService/url.service';
import { HttpClient } from '@angular/common/http';
import { CriteriosClasificacion } from '../models/criterios-clasificacion';
import { Observable } from 'rxjs';
import { Respuesta } from '../models/respuesta';
import { map } from 'rxjs/operators';

@Injectable()
export class CriteriosClasificacionService {
  private apiRoute: string;

  constructor(private urlService: UrlService, private http: HttpClient,) {
    this.apiRoute = `${this.urlService.getBaseUrl()}CriteriosClasificacion/`;
  }

  getAllCriteriosClasificacion(): Observable<CriteriosClasificacion[]> {
    return this.http.get<CriteriosClasificacion[]>(`${this.apiRoute}GetAllCriteriosClasificacion`);
  }

  removeCriteriosClasificacion(id: number): Observable<string[]> {
    return this.http.delete<string[]>(`${this.apiRoute}removeCriteriosClasificacion/${id}`);
  }

  updateCriteriosClasificacion(criteriosClasificacion: CriteriosClasificacion): Observable<Respuesta> {
    return this.http.post<Respuesta>(`${this.apiRoute}updateCriteriosClasificacion`, criteriosClasificacion);
  }

  createCriteriosClasificacion(criteriosClasificacion: CriteriosClasificacion): Observable<Respuesta> {
    return this.http.post<Respuesta>(`${this.apiRoute}createCriteriosClasificacion`, criteriosClasificacion);
  }
}
